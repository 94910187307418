import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './OrderCounts.css';
import { fetchOrderCounts, selectOrderCounts, updateOrderCount, setFilter } from '../../redux/slices/orderCountsSlice';
import Navbar from '../../components/navbar/Navbar';
import axios from 'axios';

const OrderCounts = () => {
  const dispatch = useDispatch();
  const orderCounts = useSelector(selectOrderCounts);
  const sortedOrderCounts = orderCounts ? [...orderCounts].sort((a, b) => new Date(b.order_date) - new Date(a.order_date)) : [];

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    order_date: '',
    order_count: '',
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Сегодня');
  const [displayedOption, setdisplayedOption] = useState('Еще');
  const [dropdownSelected, setDropdownSelected] = useState(false);
  const [activeFilter, setActiveFilter] = useState('thisMonth');

  useEffect(() => {
    dispatch(fetchOrderCounts());
    dispatch(setFilter(activeFilter));
  }, [dispatch, activeFilter]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      timeZone: 'Europe/Moscow'
    });
  };

  const revertDateFormat = (formattedDate) => {
    const dateParts = formattedDate.split(' ');
    const months = {
      'января': '01',
      'февраля': '02',
      'марта': '03',
      'апреля': '04',
      'мая': '05',
      'июня': '06',
      'июля': '07',
      'августа': '08',
      'сентября': '09',
      'октября': '10',
      'ноября': '11',
      'декабря': '12'
    };
    const day = dateParts[0];
    const month = months[dateParts[1]];
    const year = dateParts[2].replace('г.', '').trim();
    return `${year}-${month}-${day.padStart(2, '0')}`;
  };  

  const handleEdit = (order) => {
    setFormData({
      ...order,
      order_date: formatDate(order.order_date) 
    });
    setShowModal(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  }

  const handleDropdownSelect = (option) => {
    setSelectedOption(option);
    setdisplayedOption(option);
    setDropdownOpen(false);
    setDropdownSelected(true);
    setActiveFilter(option);
    dispatch(setFilter(option));
  };

  const handleRadioChange = (event) => {
    const period = event.target.value;
    setActiveFilter(period);
    setDropdownSelected(false);
    setDropdownOpen(false)
    setdisplayedOption('Еще');
    setSelectedOption('Сегодня');
    dispatch(setFilter(period));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;

    if (form.checkValidity() === false) {
      form.classList.add('was-validated');
      return;
    }

    try {
      const dateInOriginalFormat = revertDateFormat(formData.order_date);

      await axios.put(`https://taxi-ru-api.vercel.app/api/order-count`, {
        date: dateInOriginalFormat, 
        newCount: formData.order_count
      });
      dispatch(updateOrderCount(formData));
      dispatch(fetchOrderCounts());
      setShowModal(false);
    } catch (error) {
      console.error('Error saving order count:', error);
    }
  };

  return (
    <div className='order-counts-page'>
      <Navbar />
      <div className="order-counts-container">
        <div className="order-counts-table-controls">
          <h2>Кол-во Заказов:</h2>
          <div className="radio-button-group">
            <label className="radio-button">
              <input type="radio" name="fixed_period" value="30days" onChange={handleRadioChange} checked={activeFilter === '30days'} />
              <span className="radio-label">30 дней</span>
            </label>
            <label className="radio-button">
              <input type="radio" name="fixed_period" value="90days" onChange={handleRadioChange} checked={activeFilter === '90days'} />
              <span className="radio-label">90 дней</span>
            </label>
            <label className="radio-button">
              <input type="radio" name="fixed_period" value="thisMonth" onChange={handleRadioChange} checked={activeFilter === 'thisMonth'} />
              <span className="radio-label">Этот месяц</span>
            </label>
            <label className="radio-button">
              <input type="radio" name="fixed_period" value="thisYear" onChange={handleRadioChange} checked={activeFilter === 'thisYear'} />
              <span className="radio-label">Этот год</span>
            </label>
            <div className={`dropdown ${dropdownOpen ? 'open' : ''}`}>
              <button className={`dropdown-button ${dropdownSelected ? 'selected' : ''}`} onClick={handleDropdownToggle}>
                {displayedOption} <svg width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="m8 9.583 4.793-4.79a1 1 0 0 1 1.414 1.414l-5.5 5.497a1 1 0 0 1-1.414 0l-5.5-5.497a1 1 0 0 1 1.414-1.414L8 9.583Z" fill="currentColor"></path></svg>
              </button>
              {dropdownOpen && (
                <div className='dropdown-menu'>
                  {['Сегодня', 'Вчера', 'Прошлый месяц', 'Прошлый квартал', 'Прошлый год', '7 дней', '10 дней', '180 дней', '365 дней'].map(option => (
                    <div key={option} className={`dropdown-item ${option === selectedOption ? 'selected' : ''}`} onClick={() => handleDropdownSelect(option)}>
                      {option}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="order-counts-table-container">
          <table className="order-counts-table">
            <thead>
              <tr>
                <th>Дата</th>
                <th className='count-column'>Кол-во</th>
                <th className='action-column'>Действие</th>
              </tr>
            </thead>
            <tbody>
              {sortedOrderCounts.length > 0 ? (
                sortedOrderCounts.map((orderCount, index) => (
                  <tr key={index}>
                    <td>{formatDate(orderCount.order_date)}</td>
                    <td>{orderCount.order_count}</td>
                    <td className="order-counts-button-group">
                      <button className="edit-button" onClick={() => handleEdit(orderCount)}>
                        <i className="fas fa-edit"></i>
                      </button>
                    </td>
                  </tr>
                ))
              ): (
                <tr>
                  <td colSpan="3">
                    <h3>Не хватает данных, попробуйте выбрать другой период</h3>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {showModal ? (
        <div className={`modal ${showModal ? 'show' : ''}`}>
          <div className="modal-content">
            <h2>Изменить Кол-во Заказов</h2>
            <form className="needs-validation" onSubmit={handleSubmit} noValidate>
              <div className="form-group">
                <label htmlFor="order_date">Дата:</label>
                <input
                  type="text"
                  name="order_date"
                  value={formData.order_date}
                  disabled
                />
              </div>
              <div className="form-group">
                <label htmlFor='order_count'>Кол-во:</label>
                <input
                  type="number"
                  className='form-control'
                  name="order_count"
                  placeholder='Кол-во'
                  value={formData.order_count}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">Укажите кол-во</div>
              </div>
              <div className="form-buttons">
                <button type="submit">Сохранить</button>
                <button type="button" onClick={() => setShowModal(false)}>
                  Отмена
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OrderCounts;
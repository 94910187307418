import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Analytics } from "@vercel/analytics/react";
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import CurrentOrders from './pages/current-orders/CurrentOrders';
import CustomerRecords from './pages/customer-records/CustomerRecords';
import OrderCounts from './pages/order-counts/OrderCounts';

function App() {
  const isAuthenticated = useSelector(state => state.password.isAuthenticated);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />
        <Route path="/" element={isAuthenticated ? <Home /> : <Navigate to="/login" />} />
        <Route path="/current-orders" element={isAuthenticated ? <CurrentOrders /> : <Navigate to="/login" />} />
        <Route path="/customer-records" element={isAuthenticated ? <CustomerRecords /> : <Navigate to="/login" />} />
        <Route path="/order-counts" element={isAuthenticated ? <OrderCounts /> : <Navigate to="/login" />} />
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './CustomerRecords.css';
import { fetchCustomers, selectCustomers, setSearchQuery } from '../../redux/slices/customersSlice';
import Navbar from '../../components/navbar/Navbar';
import axios from 'axios';

const CustomerRecords = () => {
  const dispatch = useDispatch();
  const customers = useSelector(selectCustomers);
  const reversedCustomers = customers ? [...customers].reverse() : [];

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    phone_number: '',
    city: ''
  });

  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  const handleSearchChange = (event) => {
    dispatch(setSearchQuery(event.target.value));
  };

  const handleAdd = () => {
    setFormData({
      id: '',
      phone_number: '',
      city: ''
    });
    setShowModal(true);
  };

  const handleEdit = (customer) => {
    setFormData(customer);
    setShowModal(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://taxi-ru-api.vercel.app/api/customers/${id}`);
      dispatch(fetchCustomers());
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;

    if (form.checkValidity() === false) {
      form.classList.add('was-validated');
      return;
    }

    try {
      if (formData.id) {
        await axios.put(`https://taxi-ru-api.vercel.app/api/customers/${formData.id}`, {
          phone_number: formData.phone_number, 
          city: formData.city
        });
      } else {
          await axios.post('https://taxi-ru-api.vercel.app/api/customers', {
            phone_number: formData.phone_number, 
            city: formData.city
          });
      }
      dispatch(fetchCustomers());
      setShowModal(false);
    } catch (error) {
      console.error('Error saving customer:', error);
    }
  };

  return (
    <div className='customer-records-page'>
      <Navbar />
      <div className="customer-records-container">
        <div className="customer-records-table-controls">
          <div className="customers-count">
            <h2>Клиенты:</h2> <strong>{customers ? customers.length : 0}</strong>
          </div>
          <div className="customer-records-search-bar-container">
            <i className="fas fa-search customer-records-search-icon"></i>
            <input 
              type="text" 
              className="customer-records-search-bar" 
              placeholder="Поиск клиентов..." 
              onChange={handleSearchChange}
            />
          </div>
          <div className="customer-records-button-group">
            <button onClick={handleAdd}>Добавить Клиента</button>
          </div>
        </div>
        <div className="customer-records-table-container">
          <table className="customer-records-table">
            <thead>
              <tr>
                <th>Номер Телефона</th>
                <th className='city-column'>Город</th>
                <th className='customer-records-action-column'>Действие</th>
              </tr>
            </thead>
            <tbody>
              {reversedCustomers.map((customer, index) => (
                <tr key={index} className='customer-item'>
                  <td>{customer.phone_number}</td>
                  <td>{customer.city}</td>
                  <td className="customer-records-button-group">
                    <button onClick={() => handleEdit(customer)}>
                      <i className="fas fa-edit"></i>
                    </button>
                    <button onClick={() => handleDelete(customer.id)}>
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showModal ? (
        <div className={`modal ${showModal ? 'show' : ''}`}>
          <div className="modal-content">
            <h2>{formData.id ? 'Изменить клиента' : 'Добавить Клиента'}</h2>
            <form className="needs-validation" onSubmit={handleSubmit} noValidate>
              <div className="form-group">
                <label htmlFor="phon_number">Номер Телефона:</label>
                <input
                  type="tel"
                  className='form-control'
                  name="phone_number"
                  placeholder='Номер телефона'
                  value={formData.phone_number}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">Укажите номер телефона клиента</div>
              </div>
              <div className="form-group">
                <label htmlFor='city'>Город:</label>
                <input
                  type="text"
                  className='form-control'
                  name="city"
                  placeholder='Город'
                  value={formData.city}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">Укажите город клиента</div>
              </div>
              <div className="form-buttons">
                <button type="submit">Сохранить</button>
                <button type="button" onClick={() => setShowModal(false)}>
                  Отмена
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CustomerRecords;
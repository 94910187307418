import axios from 'axios';

const fetchSuggestions = async (text) => {
  try {
    const response = await axios.get('https://taxi-ru-api.vercel.app/api/geosuggest', {
      params: {
        text: text
      }
    });

    console.log('Full geosuggest response data:', response.data);

    const results = response.data.results;
    if (!results) {
      return [];
    }

    return results.map(result => {
      const localityComponent = result.address.component.find(component => component.kind.includes("LOCALITY"));
      const locality = localityComponent ? localityComponent.name : 'Неизвестно';

      return {
        title: result.title.text,
        titleHl: result.title.hl,
        subtitle: result.subtitle.text,
        subtitleHl: result.subtitle.hl || [],
        locality: locality,
        uri: result.uri
      };
    });
  } catch (error) {
    console.error('Error fetching suggestions:', error);
    return [];
  }
};

export default fetchSuggestions;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './CurrentOrders.css';
import { fetchCurrentOrders, selectCurrentOrders, setSearchQuery } from '../../redux/slices/currentOrdersSlice';
import Navbar from '../../components/navbar/Navbar';
import axios from 'axios';

const CurrentOrders = () => {
  const dispatch = useDispatch();
  const currentOrders = useSelector(selectCurrentOrders);
  const reversedCurrentOrders = [...currentOrders].reverse();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    address_from: '',
    address_to: '',
    tariff_class: 'econom',
    phone_number: ''
  });

  useEffect(() => {
    dispatch(fetchCurrentOrders());
  }, [dispatch]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://taxi-ru-api.vercel.app/api/current-orders/${id}`);
      dispatch(fetchCurrentOrders());
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  const handleEdit = (order) => {
    setFormData(order);
    setShowModal(true);
  };

  const handleAdd = () => {
    setFormData({
      id: '',
      address_from: '',
      address_to: '',
      tariff_class: 'econom',
      phone_number: ''
    });
    setShowModal(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSearchChange = (event) => {
    dispatch(setSearchQuery(event.target.value));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;

    if (form.checkValidity() === false) {
      form.classList.add('was-validated');
      return;
    }

    try {
      if (formData.id) {
        await axios.put(`https://taxi-ru-api.vercel.app/api/current-orders/${formData.id}`, formData);
      } else {
        await axios.post('https://taxi-ru-api.vercel.app/api/current-orders', formData);
      }
      dispatch(fetchCurrentOrders());
      setShowModal(false);
    } catch (error) {
      console.error('Error saving order:', error);
    }
  };

  return (
    <div className="current-orders-page">
      <Navbar />
      <div className="current-orders-container">
        <div className="current-orders-table-controls">
          <div className="current-orders-count">
            <h2>Текущие Заказы:</h2> <strong>{currentOrders.length}</strong>
          </div>
          <div className="current-orders-search-bar-container">
            <i className="fas fa-search current-orders-search-icon"></i>
            <input 
              type="text" 
              className="current-orders-search-bar" 
              placeholder="Поиск заказа..." 
              onChange={handleSearchChange}
            />
          </div>
          <div className="current-orders-button-group">
            <button onClick={handleAdd}>Добавить Заказ</button>
          </div>
        </div>
        <div className="current-orders-table-container">
          <table className="current-orders-table">
            <thead>
              <tr>
                <th>Откуда</th>
                <th>Куда</th>
                <th>Тариф</th>
                <th>Телефон</th>
                <th>Время</th>
                <th>Действие</th>
              </tr>
            </thead>
            <tbody>
              {reversedCurrentOrders.map(order => (
                <tr key={order.id} className="order-item">
                  <td>{order.address_from}</td>
                  <td>{order.address_to}</td>
                  <td>{order.tariff_class}</td>
                  <td>{order.phone_number}</td>
                  <td>
                    {new Date(order.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </td>
                  <td className="current-orders-button-group">
                    <button onClick={() => handleEdit(order)}>
                      <i className="fas fa-edit"></i>
                    </button>
                    <button onClick={() => handleDelete(order.id)}>
                      <i className="fas fa-check"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showModal ? (
        <div className={`modal ${showModal ? 'show' : ''}`}>
          <div className="modal-content">
            <h2>{formData.id ? 'Изменить Заказ' : 'Добавить Заказ'}</h2>
            <form className="needs-validation" onSubmit={handleSubmit} noValidate>
              <div className="form-group">
                <label htmlFor="address_from">Откуда:</label>
                <input
                  type="text"
                  className='form-control'
                  name="address_from"
                  placeholder='Откуда'
                  value={formData.address_from}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">Укажите откуда забирать</div>
              </div>
              <div className="form-group">
                <label htmlFor='address_to'>Куда:</label>
                <input
                  type="text"
                  className='form-control'
                  name="address_to"
                  placeholder='Куда'
                  value={formData.address_to}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">Укажите куда ехать</div>
              </div>
              <div className="form-group">
                <label>Тариф:</label>
                <select
                  className='form-control'
                  name="tariff_class"
                  value={formData.tariff_class}
                  onChange={handleChange}
                  required
                >
                  <option value="econom">Эконом</option>
                  <option value="business">Комфорт</option>
                  <option value="comfortplus">Комфорт+</option>
                  <option value="minivan">Минивен</option>
                  <option value="vip">Бизнес</option>
                  <option value="express">Доставка</option>
                  <option value="courier">Курьер</option>
                </select>
              </div>
              <div className="form-group">
                <label>Телефон:</label>
                <input
                  type="tel"
                  className='form-control'
                  name="phone_number"
                  placeholder='Номер телефона'
                  value={formData.phone_number}
                  onChange={handleChange}
                  required
                />
                <div className="invalid-feedback">Введите номер телефона</div>
              </div>
              <div className="form-buttons">
                <button type="submit">Сохранить</button>
                <button type="button" onClick={() => setShowModal(false)}>
                  Отмена
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CurrentOrders;
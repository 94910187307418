import React, { useState } from 'react';
import './Home.css';
import Navbar from '../../components/navbar/Navbar';
import NewOrder from '../../components/new-order/NewOrder';
import Map from '../../components/map/Map';

function Home() {
  const [CENTER, setCENTER] = useState([37.588144, 55.733842]);
  const [STARTCOORDS, setSTARTCOORDS] = useState({});
  const [ENDCOORDS, setENDCOORDS] = useState({});
  const [fromAddress, setFromAddress] = useState('');
  const [toAddress, setToAddress] = useState('');
  const [LOCALITY, setLOCALITY] = useState('');
  const [toAddressSelected, setToAddressSelected] = useState(false);

  const handleFromAddressChange = (address, coords) => {
    setFromAddress(address);
    setSTARTCOORDS(coords);
    setCENTER([coords.lon, coords.lat]);
  };

  const handleToAddressChange = (address, coords, bool) => {
    setToAddress(address);
    setENDCOORDS(coords);
    setToAddressSelected(bool);
  };

  return (
    <div className="home-container">
      <Navbar />
      <div className="home-content">
        <NewOrder 
          onFromAddressSelected={handleFromAddressChange} 
          onToAddressSelected={handleToAddressChange}
          fromAddress={fromAddress} 
          toAddress={toAddress} 
          STARTCOORDS={STARTCOORDS}
          ENDCOORDS={ENDCOORDS}
          LOCALITY={LOCALITY}
        />
        <Map 
          CENTER={CENTER} 
          STARTCOORDS={STARTCOORDS}
          ENDCOORDS={ENDCOORDS}
          onFromAddressChange={handleFromAddressChange} 
          onToAddressChange={handleToAddressChange}
          toAddressSelected={toAddressSelected}
          onLocalityChange={setLOCALITY}
        />
      </div>
    </div>
  );
}

export default Home;
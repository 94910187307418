import './Login.css';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../redux/slices/passwordSlice';

function Login() {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');

  const handleLogin = (event) => {
    event.preventDefault();

    if (password === 'Lermolas739') {
      dispatch(authenticate());
    } else {
        alert('Неверный пароль.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Войти в систему</h2>
        <form onSubmit={handleLogin}>
            <input 
            type="password" 
            value={password} 
            onChange={(event) => setPassword(event.target.value)} 
            placeholder="Введите пароль"
            required
            />
            <button type="submit">Войти</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();
  
  return (
    <nav className="navbar">
      <ul>
        <li>
          <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Сделать Заказ</Link>
        </li>
        <li>
          <Link to="/current-orders" className={location.pathname === '/current-orders' ? 'active' : ''}>Текущие Заказы</Link>
        </li>
        <li>
          <Link to="/order-counts" className={location.pathname === '/order-counts' ? 'active' : ''}>Кол-во Заказов</Link>
        </li>
        <li>
          <Link to="/customer-records" className={location.pathname === '/customer-records' ? 'active' : ''}>Клиенты</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
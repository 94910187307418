import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import axios from 'axios';

const initialState = {
  customers: [],
  searchQuery: '',
  filteredCustomers: [],
  status: 'idle',
  error: null,
};

export const fetchCustomers = createAsyncThunk('customers/fetchCustomers', async () => {
    const response = await axios.get('https://taxi-ru-api.vercel.app/api/customers');
    return response.data;
  });

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
      state.filteredCustomers = state.customers.filter(customer => 
        customer.phone_number.includes(action.payload) ||
        customer.city.toLowerCase().includes(action.payload.toLowerCase()) 
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.customers = Array.isArray(action.payload) ? action.payload : [];
        state.filteredCustomers = state.customers;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setSearchQuery } = customersSlice.actions;
export const selectCustomers = (state) => state.customers.filteredCustomers;

const persistConfig = {
  key: 'customers',
  storage,
};
  
const persistedCustomersReducer = persistReducer(persistConfig, customersSlice.reducer);

export default persistedCustomersReducer;
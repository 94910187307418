import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const passwordSlice = createSlice({
  name: 'password',
  initialState: {
    isAuthenticated: false
  },
  reducers: {
    authenticate: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    }
  }
});

export const { authenticate, logout } = passwordSlice.actions;

const persistConfig = {
  key: 'password',
  storage,
};

const persistedReducer = persistReducer(persistConfig, passwordSlice.reducer);

export default persistedReducer;